import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/felix/OneDrive/Documents/Bertram.Solutions/Website.TTcom/GatsbyJS-2022/src/components/mdx/component.js";
import { PortfolioPage, Summary, Review, Aside, Notes } from "../../../components/mdx/portfolio.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PortfolioPage;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1>{props.pageContext.frontmatter.author} {props.pageContext.frontmatter.title}</h1>
    <Summary mdxType="Summary">
      <Aside mdxType="Aside">
        <p><strong parentName="p">{`Similar Portfolios`}</strong></p>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/portfolios/tt-all-stars-monthly/"
            }}>{`All-Stars Monthly`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/portfolios/tt-mach-1/"
            }}>{`Mach-1`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/portfolios/keller-classical-asset-allocation/"
            }}>{`Classical Asset Allocation`}</a></li>
        </ul>
      </Aside>
      <ul>
        <li parentName="ul">{`Objective: balanced growth`}</li>
        <li parentName="ul">{`Type: mean-variance optimization strategy`}</li>
        <li parentName="ul">{`Invests in: ETFs tracking stocks and bonds`}</li>
        <li parentName="ul">{`Rebalancing schedule: monthly`}</li>
        <li parentName="ul">{`Taxation: 90% short-term capital gains`}</li>
        <li parentName="ul">{`Minimum account size: $5,000`}</li>
      </ul>
      <p>{`Frank Grossmann is the founder of LogicalInvest, a Zurich-based firm developing intelligent rule-based investment strategies. Grossmann published a white paper describing the `}<em parentName="p">{`SPY-TLT Universal Investment Strategy`}</em>{` (UIS) in late 2014.`}</p>
      <p>{`The `}<em parentName="p">{`Universal Investment Strategy`}</em>{` aims to be an evolved, intelligent version of the classic 60/40 portfolio. While the assets held are the same, the `}<em parentName="p">{`Universal Investment Strategy`}</em>{` adapts to current conditions by shifting weight away from stocks in challenging markets and adding weight in bullish times.`}</p>
      <p>{`The `}<em parentName="p">{`Universal Investment Strategy`}</em>{` has low maintenance requirements, as it rebalances only once per month and holds just two assets.`}</p>
    </Summary>
    <Review mdxType="Review">
      <h2>{`Strategy Rules`}</h2>
      <Aside mdxType="Aside">
        <p><strong parentName="p">{`Source`}</strong></p>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://logical-invest.com/universal-investment-strategy/"
            }}>{`Introduction to the SPY-TLT Universal Investment Strategy`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://logical-invest.com/about/about-us/"
            }}>{`Frank Grossmann`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://logical-invest.com/app/strategy/uis/universal-investment-strategy"
            }}>{`Logical Invest`}</a></li>
        </ul>
        <p><strong parentName="p">{`Learn More`}</strong></p>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://logical-invest.com/leveraged-universal-investment-strategy/"
            }}>{`Hell on Fire`}</a></li>
          <li parentName="ul">{`Presentation `}<a parentName="li" {...{
              "href": "https://www.youtube.com/watch?v=uvjSLrxbrDc"
            }}>{`Part 1`}</a>{`, `}<a parentName="li" {...{
              "href": "https://www.youtube.com/watch?v=1F-ZZnxxTXE"
            }}>{`Part 2`}</a>{`, and `}<a parentName="li" {...{
              "href": "https://www.youtube.com/watch?v=rforif7M89s"
            }}>{`Part 3`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://github.com/fbertram/TuringTrader/blob/develop/BooksAndPubs/LI_UniversalInvestmentStrategy.cs"
            }}>{`Source Code`}</a></li>
        </ul>
      </Aside>
      <p>{`The operation of the `}<em parentName="p">{`Universal Investment Strategy`}</em>{` can be summarized as follows:`}</p>
      <ul>
        <li parentName="ul">{`trade ETFs tracking the S&P 500 and long-term U.S. Treasuries`}</li>
        <li parentName="ul">{`evaluate the performance of various asset weights over a lookback period of approximately three months`}</li>
        <li parentName="ul">{`rebalance once per month, picking the combination with the best modified Sharpe Ratio`}</li>
      </ul>
      <p>{`We admire the simplicity of this approach as much as we appreciate the results. While walk-forward-optimization is a brute-force approach to finding the optimal asset allocation, it addresses the shortcomings of mathematically more elaborate methods, including Markowitz's Critical Line Algorithm.`}</p>
      <p>{`Our implementation follows Frank Grossmann's white paper as closely as possible. It is worth noting that LogicalInvest's version of the `}<em parentName="p">{`Universal Investment Strategy`}</em>{` substitutes the U.S. Treasuries for a more sophisticated hedging strategy, which remains undisclosed.`}</p>
      <h2>{`Diversification`}</h2>
      <p>{`While the `}<em parentName="p">{`Universal Investment Strategy`}</em>{` can invest up to 100% in either of its two assets, it rarely does so. Most of the time, the asset allocation is relatively conservative, with about equal allocations to equities and Treasuries.`}</p>
      <p>{`Nonetheless, investors should assume that the strategy's tail risk is similar to holding the S&P 500.`}</p>
      <h2>{`Returns & Volatility`}</h2>
      <p>{`The `}<em parentName="p">{`Universal Investment Strategy`}</em>{` beats its benchmark in many years. The strategy's long-term volatility is about the same as that of a passive 60/40 but shows significantly lower maximum drawdowns.`}</p>
      <p>{`The Monte-Carlo simulation shows how the `}<em parentName="p">{`Universal Investment Strategy`}</em>{` creates a massive upside while offering a meaningful reduction in downside risk. As a result, the strategy provides dramatically higher risk-adjusted returns than its benchmark.`}</p>
      <p>{`Because of the monthly rebalancing schedule, the `}<em parentName="p">{`Universal Investment Strategy`}</em>{` might not react swiftly enough to changes in market sentiment. Further, even the best combination of the strategy's two assets might not meet an investor's risk profile. In our opinion, the strategy's money-management should include a separate volatility dimension to address these concerns.`}</p>
      <h2>{`Account & Tax Considerations`}</h2>
      <p>{`The `}<em parentName="p">{`Universal Investment Strategy`}</em>{` trades frequently and consequently often triggers taxable events. The chart above shows that, at least outside of major economic events, the asset allocation stays within a relatively narrow range. Consequently, investors can expect that a large portion of the capital gains will qualify for long-term tax treatment, making the strategy reasonably tax-efficient.`}</p>
      <p>{`Because the strategy only holds two ETFs at any time, it can easily be implemented in accounts of $5,000 or more.`}</p>
    </Review>
    <Notes mdxType="Notes">
    </Notes>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      